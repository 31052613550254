<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import VehicleLookupService from './VehicleLookupService'
// import StoreMixin from './storeMixin'

export default {
  name: 'SubscriptionView',
  components: {},
  // mixins: [StoreMixin],
  props: {
    value: null
  },
  data: () => {
    return {
      // filter: {
      //   companyId: '',
      //   sortBy: '',
      //   sortOrder: '',
      //   pageSize: 10,
      //   pageIndex: 1
      // },
      // entities: null
    }
  },
  created() {
    // this.filter.companyId = this.$userInfo.companyId
    // this.getSubscriptionSummaries()
  },
  mounted() {},
  methods: {
    // async getSubscriptionSummaries() {
    //   this.entities = await VehicleLookupService.getSubscriptionSummaries(this.filter)
    // },
    // async addSubscription() {
    //   const result = await VehicleLookupService.addSubscription()
    //   console.log(result)
    // }
  }
}
</script>