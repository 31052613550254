import { TableColumn } from '@/classes'

const PricingColumns = [
  new TableColumn('Tier', 'tier', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('Price', 'assetName', true, 'asc', 'right')
]

const SubscriptionColumns = [
  new TableColumn('Purchase Date', 'purchaseDate', true, 'desc', 'left'),
  new TableColumn('Notes', 'notes', false, 'desc', 'left'),
  new TableColumn('Amount', 'amount', false, 'desc', 'right'),
  new TableColumn('Previous Balance', 'previousBalance', false, 'desc', 'right'),
  new TableColumn('Usage', 'usage', false, 'desc', 'right'),
  new TableColumn('Balance', 'balance', false, 'desc', 'right'),
  new TableColumn('Active', 'active', false, 'desc', 'centered'),
  new TableColumn('', '', false, 'desc', 'right')
]

export { PricingColumns, SubscriptionColumns }