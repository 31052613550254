<template>
  <div v-if="hasPermission">
    <app-detail-header :show-toggle="true"
      :show-new="$route.name === routeTypes.SubscriptionView.name"
      :show-print="false"
      :show-delete="false"
      :show-save="true"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <vehicle-lookup-side-menu ref="sideMenu" />
      </div>
      <div class="column">
        <router-view ref="childView" />
      </div>
    </div>
  </div>
  <div v-else
    class="container">
    <article class="message is-danger">
      <div class="message-header">
        <p>
          <span class="icon">
            <i class="mdi mdi-shield-alert-outline mdi-24px" />
          </span>
          <span class="is-size-4"> Access Denied</span>
        </p>
      </div>
      <div class="message-body">
        You do not have permission to access this page
      </div>
    </article>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import VehicleLookupSideMenu from './VehicleLookupSideMenu'
import { AppHeaderButtonTypes } from '@/enums'
import VehicleLookupRoutes from './route-types'

export default {
  name: 'VehicleLookupView',
  components: {
    AppDetailHeader,
    VehicleLookupSideMenu
  },
  data: () => {
    return {
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false,
      entity: null
    }
  },
  computed: {
    hasPermission() {
      return this.$userInfo && this.$userInfo.isSystemAdministrator && this.$userInfo.isSystemAccount
    },
    routeTypes() {
      return VehicleLookupRoutes
    }
  },
  watch: {},
  created() {
    // this.getPricing()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.$eventHub.$emit('subscription-new')
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          // console.log(this.$route.name)
          if (this.$route.name === VehicleLookupRoutes.PricingView.name) {
            // this.$eventHub.$emit('pricing-save')
            this.$refs.childView.save()
          } else if (this.$route.name === VehicleLookupRoutes.SubscriptionView.name) {
            this.$eventHub.$emit('subscription-save')
            // this.$refs.childView.save()
          }
          // this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    }
  }
}
</script>