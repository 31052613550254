<template>
  <div>
    Subscription Detail
  </div>
</template>

<script>
export default {
  name: 'SubscriptionDetail'
}
</script>