<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :total-rows="totalRows"
            :is-loading="isTableLoading"
            :show-pagination="false">
            <tr v-for="(price, index) in innerValue"
              :key="price.tier"
              :class="{ 'is-selected' : selectedRow === index }"
              @click="highlightSelected(index, $event)">
              <td class="has-vertical-middle">{{ price.tier }}</td>
              <td>
                <input class="input"
                  v-model="price.description">
              </td>
              <td class="money-input">
                <vue-numeric class="input has-text-right"
                  v-model.number="price.price"
                  :min="0"
                  :precision="2" />
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import VueNumeric from '@/components/VueNumeric'
import { PricingColumns } from './columns'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PricingDetail',
  components: {
    BulmaTable,
    VueNumeric
  },
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null,
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false
    }
  },
  computed: {
    columns() {
      return PricingColumns
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.totalRows = this.innerValue.length
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    }
  }
}
</script>

<style lang="scss" scoped>
.money-input {
  max-width: 2em;
}
</style>