<template>
  <div v-if="hasPermission">
    <router-view v-if="entity"
      v-model="entity" />
  </div>
  <div v-else
    class="container">
    <article class="message is-danger">
      <div class="message-header">
        <p>
          <span class="icon">
            <i class="mdi mdi-shield-alert-outline mdi-24px" />
          </span>
          <span class="is-size-4"> Access Denied</span>
        </p>
      </div>
      <div class="message-body">
        You do not have permission to access this page
      </div>
    </article>
  </div>
</template>

<script>
import VehicleLookupService from './VehicleLookupService'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PricingView',
  components: {},
  mixins: [StoreMixin],
  data: () => {
    return {
      selectedRow: null,
      totalRows: 0,
      isTableLoading: false,
      entity: null
    }
  },
  computed: {
    hasPermission() {
      return this.$userInfo && this.$userInfo.isSystemAdministrator //&& this.$userInfo.isSystemAccount
    }
  },
  watch: {
    entity: {
      handler: function(newVal) {
        if (newVal) {
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      },
      deep: true
    }
  },
  created() {
    this.getPricing()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    async getPricing(reset) {
      this.isTableLoading = true
      if (reset) {
        this.clearSnapshots()
      }
      await this.getStoreItem(0)
      this.entity = _cloneDeep(this.currentSnapshot)
      this.totalRows = this.entity.length
      this.isTableLoading = false
    },
    async save() {
      if (this.snapshotDiff) {
        this.$showSpinner()
        await VehicleLookupService.savePricing(this.entity)
        await this.getPricing(true)
        this.$hideSpinner()
        this.$toast.open({
          message: 'Pricing table updated',
          type: 'is-success'
        })
      } else {
        this.$toast.open({
          message: 'No changes. Not saved',
          type: 'is-warning'
        })
      }
    }
  }
}
</script>